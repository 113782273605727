<template>
  <div class="avatar-component-wrapper">
    <div
      :class="{ 'dark-background': darkBackground, 'hide-background': hideBackground }"
      class="background"
    >
      <template v-if="showTopology">
        <template v-if="!useMaskFromUrl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 80 80"
            class="mask"
          >
            <circle
              cx="40"
              cy="40"
              r="38"
              class="photo-placeholder"
              :class="{ 'for-entity-name-initials': showEntityNameInitialsInsteadOfAvatarPicture }"
            />
            <g transform="translate(-2 -2)">
              <g
                v-if="showTopologySquares"
              >
                <path
                  id="Symbols_bg"
                  data-name="Symbols bg"
                  d="m 15.239541,67.998455 53.942456,-0.0047 c -2.289669,2.96826 -13.688443,12.003167 -27.334621,11.642178 -13.402204,0.229674 -24.4736,-7.89782 -26.607835,-11.637478 z"
                  class="avatar-mask-symbols-background"
                />
                <!--              d="M8.5,60.5h66a38,38,0,0,1-33,18A38.06,38.06,0,0,1,8.5,60.5Z"-->
                <rect
                  id="Symbols_box"
                  data-name="Symbols box"
                  x="26.5"
                  y="64.5"
                  width="30"
                  height="7"
                  rx="2"
                  ry="2"
                  class="avatar-mask-symbols-background-smaller"
                />
                <rect
                  x="28.5"
                  y="65.5"
                  width="5"
                  height="5"
                  :class="elementsOrdered.length === 4 ? `square-${elementsOrdered[0]}` : 'avatar-mask-square-no-elements'"
                />
                <rect
                  x="35.5"
                  y="65.5"
                  width="5"
                  height="5"
                  :class="elementsOrdered.length === 4 ? `square-${elementsOrdered[1]}` : 'avatar-mask-square-no-elements'"
                />
                <rect
                  x="42.5"
                  y="65.5"
                  width="5"
                  height="5"
                  :class="elementsOrdered.length === 4 ? `square-${elementsOrdered[2]}` : 'avatar-mask-square-no-elements'"
                />
                <rect
                  x="49.5"
                  y="65.5"
                  width="5"
                  height="5"
                  :class="elementsOrdered.length === 4 ? `square-${elementsOrdered[3]}` : 'avatar-mask-square-no-elements'"
                />
              </g>
              <text
                v-if="showEntityNameInitialsInsteadOfAvatarPicture"
                class="svg-entity-name-initials"
                x="50%"
                y="50%"
                text-anchor="middle"
                transform="translate(2, 7)"
              >
                {{ entityNameInitials }}
              </text>
            </g>
            <circle
              id="Element_ring"
              data-name="Element ring"
              cx="40"
              cy="40"
              r="38"
              style="fill: none;stroke-miterlimit: 10;stroke-width: 3px;"
              :style="{ stroke: entityColorForCurrentMode }"
            />
          </svg>
        </template>
        <template v-else>
          <!---->
          <!-- Mask from API -->
          <!---->
          <!-- show the initials here too (without background/photo placeholder), above the mask -->
          <svg
            v-if="globalStore.isAppJgo && globalStore.isNotThemeV3"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 80 80"
            class="mask"
          >
            <circle
              cx="40"
              cy="40"
              r="38"
              class="photo-placeholder"
              :class="{ 'for-entity-name-initials': showEntityNameInitialsInsteadOfAvatarPicture }"
            />
          </svg>
          <img
            v-if="useMaskFromUrl && maskUrl && typeof maskUrl === 'string'"
            :src="maskUrl"
            class="mask"
          >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 80 80"
            class="mask"
          >
            <g transform="translate(-2 -2)">
              <text
                v-if="showEntityNameInitialsInsteadOfAvatarPicture"
                class="svg-entity-name-initials"
                x="50%"
                y="50%"
                text-anchor="middle"
                transform="translate(2, 7)"
              >
                {{ entityNameInitials }}
              </text>
            </g>
          </svg>
        </template>
      </template>
      <svg
        v-if="!showTopology"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 80 80"
        class="mask"
      >
        <circle
          cx="40"
          cy="40"
          r="38"
          class="avatar-border-no-topology"
          shape-rendering="geometricPrecision"
        />
        <!-- Note on this circle above:
         stroke is 4px (2px inside the path and 2px outside), so the radius have to be subtracted 2px (40 - 2 = 38) -->
      </svg>
      <template v-if="showMeWordInsteadOfAvatar">
        <div class="me-word-instead-of-avatar">
          me
        </div>
      </template>
      <template v-else-if="isJobCheckDig">
        <div class="job-check-dig-shovel-wrapper">
          <AppIcon
            icon-name="tly-shovel"
            :show-outer-circle="false"
          />
        </div>
      </template>
      <template v-else-if="isJobCheckPerfectCandidate">
        <div class="job-check-dig-shovel-wrapper active">
          <AppIcon
            icon-name="tly-shovel"
            :show-outer-circle="false"
            :active="true"
          />
        </div>
      </template>
      <template v-else-if="showDefaultTeamAvatar">
        <div class="avatar-image-outer-wrapper">
          <div class="avatar-image-inner-wrapper">
            <img
              src="/img/avatars/team-default-thumb.png"
            >
          </div>
        </div>
      </template>
      <template v-else-if="entity && 'avatar' in entity && entity.avatar && !showEntityNameInitialsInsteadOfAvatarPicture && typeof entity.avatar === 'string'">
        <div class="avatar-image-outer-wrapper">
          <div class="avatar-image-inner-wrapper">
            <img
              :src="entity.avatar"
              @error="onAvatarImageError"
            >
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script
  setup
  lang="ts"
>
import { type PropType, computed, ref, watchEffect } from 'vue';

import type { UsersListItem } from '@/stores/api';
import { useGlobalStore } from '@/stores/global';

import AppIcon from '@/components/misc/AppIcon.vue';

import type { CustomEntity } from '@/components/management/TableCellEntityAvatarNameEmail.vue';
import type { Entity } from '@/components/impulse-area/EntityBlockWithChart.vue';
import type { CircleOfTheElementsFilteredEntity } from '@/components/utils/circle-of-the-elements/CircleOfTheElements.vue';

const props = defineProps({
  entity: {
    type: Object as PropType<UsersListItem | CustomEntity | Entity | CircleOfTheElementsFilteredEntity | Record<string, unknown>>,
    default: undefined,
  },
  hideBackground: {
    type: Boolean,
    default: false,
  },
  darkBackground: {
    type: Boolean,
    default: false,
  },
  showTopology: {
    type: Boolean,
    default: true,
  },
  showMeWordInsteadOfAvatar: {
    type: Boolean,
    default: false,
  },
  showTopologySquares: {
    type: Boolean,
    default: true,
  },
  isTeam: {
    type: Boolean,
    default: false,
  },
  isJobCheckDig: {
    type: Boolean,
    default: false,
  },
  isJobCheckPerfectCandidate: {
    type: Boolean,
    default: false,
  },
  showDefaultAvatarInsteadOfInitials: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  avatarImageError: [error: Event];
}>();

const globalStore = useGlobalStore();

// const busySortingElements = ref(false);
// const randomID = Math.random().toString(36).replace(/[^a-z]+/g, '');
const entityElementsOrderActiveMode = ref<string[]>([]);
const entityElementsOrderBasicMode = ref<string[]>([]);

const maskUrl = computed(() => {
  if (!(props.entity && 'has_talent_manual' in props.entity && props.entity.has_talent_manual)) {
    return null;
  }

  let active;
  if ('active' in props.entity) {
    active = props.entity.active;
  }

  let isActiveMode;
  if ('isActiveMode' in props.entity) {
    isActiveMode = props.entity.isActiveMode;
  }

  let basic;
  if ('basic' in props.entity) {
    basic = props.entity.basic;
  }

  let defaultMask;
  if ('mask' in props.entity) {
    defaultMask = props.entity.mask;
  }

  if (isActiveMode === undefined) {
    return defaultMask;
  }

  if (isActiveMode && active && typeof active === 'object' && 'mask' in active) {
    return active.mask;
  }

  if (!isActiveMode && basic && typeof basic === 'object' && 'mask' in basic) {
    return basic.mask;
  }

  return defaultMask;
});

const entityColorForCurrentMode = computed(() => {
  const defaultColor = '#C4D9FF'; // $theme-v3e-blue-3

  const modeColor = (mode: any) => mode && typeof mode === 'object' && 'color' in mode && mode.color;

  const { entity } = props;
  if (!(entity && 'active' in entity && modeColor(entity.active) && 'basic' in entity && modeColor(entity.basic))) {
    return defaultColor;
  }

  const { active, basic } = entity;

  if (!('isActiveMode' in entity)) {
    return modeColor(active) || defaultColor;
  }

  return entity.isActiveMode ? modeColor(active) : modeColor(basic);
});

const elementsOrdered = computed(() => {
  if (!props.entity) {
    return [];
  }

  if ('isActiveMode' in props.entity) {
    return entityElementsOrderActiveMode.value;
  }

  return entityElementsOrderBasicMode.value;
});

const useMaskFromUrl = computed(() => elementsOrdered.value.length !== 4 && maskUrl.value);
// const useMaskFromUrl = computed(() => false); // 2023-04-11: never use the SVG version? (has an old style, dark grey) // 2023-04-17 we need it some places, e.g. ACL, where target_user has mask but has no topology from where we can build it

const showEntityNameInitialsInsteadOfAvatarPicture = computed(() => !(props.showMeWordInsteadOfAvatar || props.isTeam || props.isJobCheckDig || props.isJobCheckPerfectCandidate || props.showDefaultAvatarInsteadOfInitials)
  && props.entity && ((!('avatar' in props.entity && props.entity.avatar) && 'first_name' in props.entity && props.entity.first_name) || ('avatar' in props.entity && props.entity.avatar && typeof props.entity.avatar === 'string' && props.entity.avatar.includes('uploads/entities/avatars/thumb'))));

// the custom teams have the same default avatar as the users (s3 /uploads/entities/avatars/thumb.png):
const showDefaultTeamAvatar = computed(() => (props.isTeam && showEntityNameInitialsInsteadOfAvatarPicture.value) || (props.isTeam && !(props.entity && 'avatar' in props.entity && props.entity.avatar)));

const entityNameInitials = computed(() => {
  if (!props.entity) {
    return '';
  }
  if ('first_name' in props.entity && props.entity.first_name && props.entity.last_name && typeof props.entity.first_name === 'string' && typeof props.entity.last_name === 'string') {
    return `${props.entity.first_name[0].toUpperCase()}${props.entity.last_name[0].toUpperCase()}`;
  }
  if ('first_name' in props.entity && props.entity.first_name && typeof props.entity.first_name === 'string') {
    return props.entity.first_name[0].toUpperCase();
  }
  if ('nickname' in props.entity && props.entity.nickname && typeof props.entity.nickname === 'string') {
    return props.entity.nickname[0].toUpperCase();
  }
  return '';
});

function getElementsOrder() {
  entityElementsOrderActiveMode.value = [];
  entityElementsOrderBasicMode.value = [];

  if (!props.entity) {
    return;
  }

  // use [...arr] to avoid mutating it!
  const sortFromHighestToLowestValueAndGetElementLabel = (rows: { value: number; label: string }[]) => [...rows].sort((a, b) => (b.value - a.value)).map((row) => row.label);

  if ('active' in props.entity && props.entity.active && typeof props.entity.active === 'object' && 'rows' in props.entity.active && Array.isArray(props.entity.active.rows)) {
    entityElementsOrderActiveMode.value = sortFromHighestToLowestValueAndGetElementLabel(props.entity.active.rows);
  }
  if ('basic' in props.entity && props.entity.basic && typeof props.entity.basic === 'object' && 'rows' in props.entity.basic && Array.isArray(props.entity.basic.rows)) {
    entityElementsOrderBasicMode.value = sortFromHighestToLowestValueAndGetElementLabel(props.entity.basic.rows);
  }
}

watchEffect(() => {
  if (props.entity) {
    getElementsOrder();
  }
});

function onAvatarImageError(error: Event) {
  emit('avatarImageError', error);
}
</script>

<style
  lang="scss"
  scoped
>
.avatar-component-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.background {
  position: relative;
  height: 100%;
  /*padding: 0.4vw;*/
  padding: 8%;
  background-color: $light;
  border: 1px solid #1a1a18;
  display: flex;
  flex: 1; // fix avatar missing (no width) on Edge browser
  border-radius: $border-radius-6px;

  &.hide-background {
    padding: 0;
    background: transparent;
    border: none;

    .mask {
      padding: 0;
    }
  }

  &.dark-background {
    background-color: var(--dark);
  }
}

.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 12%;
  z-index: 1; // above the image
}

.me-word-instead-of-avatar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.avatar-image-outer-wrapper {
  /*display: none;*/
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 14%;
  /*border: 1px solid red;*/
  /*z-index: 2;*/

  .hide-background & {
    padding: 3%;
  }

  .avatar-image-inner-wrapper {
    display: flex; // fixes img outside the div at the bottom (higher than 100%) on dropdowns
    height: 100%; // fixes image out of place and too high on Safari
    border-radius: 50%;
    overflow: hidden;
    /*padding: 2px;*/
    /*border: 1px solid red;*/
    background-color: #eff0f0; // because some avatars might be transparent

    .theme-v3e & {
      background-color: var(--columns-background);
    }

    img {
      width: 100%;
      image-rendering: -moz-crisp-edges; /* Firefox */
      image-rendering: -o-crisp-edges; /* Opera */
      image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    }
  }
}

.avatar-mask-symbols-background {
  fill: #272d33;

  .theme-v3e & {
    //fill: #fff;
    fill: var(--text-color-1);
    opacity: 1;
  }
}

.avatar-mask-symbols-background-smaller {
  fill: #fff;
}

.avatar-mask-square-no-elements {
  fill: var(--text-color-3);
}

.square-fire,
.square-feuer {
  fill: var(--fire-color);
}

.square-air,
.square-luft {
  fill: var(--air-color);
}

.square-water,
.square-wasser {
  fill: var(--water-color);
}

.square-earth,
.square-erde {
  fill: var(--earth-color);
}

.avatar-border-no-topology {
  fill: none;
  //stroke: #272d33; // 2023-04-11 this was the old dark color
  stroke: $theme-v3e-blue-1;
  stroke-width: 4px;
}

.svg-entity-name-initials {
  font-size: $font-18px;
  font-weight: bold;
  fill: var(--text-color-1);
}

.photo-placeholder {
  fill: none;

  &.for-entity-name-initials {
    fill: $theme-v3e-blue-4;
  }
}

.job-check-dig-shovel-wrapper {
  width: 100%;
  height: 100%;
  background-color: var(--text-color-1);
  border-radius: 50%;

  &.active {
    background-color: var(--accent-color);
  }

  .app-icon {
    width: 100%;
    height: 100%;
  }
}
</style>
